import { Button } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import './ProductCard.css'
import { currencyFormat, formatNumber, lotLPNConverter } from '@/custom/common/components'
import { mapData } from '@/custom/common/components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import debounce from 'lodash.debounce'

const CartItem = (props) => {
    const [product, setProduct] = useState()
    const [reload, setReload] = useState(false)

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        quantity: Yup.number()
            .min(1, `${'min quantity'} `)
            .max(
                product ? product.projects_qty - product.projects_sold : 0,
                `${'max quantity'} ${product ? product.projects_qty - product.projects_sold : 0}`,
            )
            .required('Enter quantity'),
    })

    const formik = useFormik({
        initialValues: {
            quantity: 0,
        },
        validationSchema: validationArray,
        onSubmit: debounce((values) => {
            addToCart({
                cart: [
                    {
                        product_id: product.project_id,
                        qty: values.quantity,
                    },
                ],
                customBidData: {
                    location_id: parseInt(product?.projects_location_id || 0),
                },
            })
        }, 1000),
    })

    useEffect(() => {
        if (product) {
            formik.values.quantity = product ? product.qty : 0
            setReload(!reload)
        }
    }, [product])

    useEffect(() => {
        if (product) {
            if (formik.values.quantity !== product.qty) {
                formik.submitForm()
            }
        }
    }, [formik.values.quantity])

    const formikQuantity = [
        {
            label: '',
            name: 'quantity',
            type: 'number',
            placeholder: 'Qty',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div className="itemInfo d-flex justify-content-start">
                            <div className="pclImg">
                                <img
                                    src={
                                        product.second_image
                                            ? product.second_image
                                            : product.avatarorg
                                    }
                                    alt={product.title}
                                />
                            </div>
                            <div className="listContent">
                                <h2 className="listProdTitle">{product.projects_title}</h2>
                                {props.from == 'invoice' ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{'Amount'}:</span>
                                                <span>${formatNumber(product.amount)}</span>
                                            </h5>
                                            <h5>
                                                <span>{'Quantity'}:</span>{' '}
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            {props.from !== 'invoice' ? (
                                                <>
                                                    <h5>
                                                        <span>{'Total'}:</span>
                                                        <span>
                                                            $
                                                            {formatNumber(
                                                                product.sub_total_amt,
                                                                2,
                                                                2,
                                                            )}
                                                        </span>
                                                    </h5>
                                                </>
                                            ) : null}

                                            {/* {props.from == 'invoice' &&
                                    product.buynow_autype === 'buynow' ? (
                                        <h5>
                                            <span>Delivery Status:</span>
                                            <span>
                                                {product.delivered === 1
                                                    ? 'In Transit'
                                                    : product.delivered === 2
                                                    ? 'Ready for Pickup'
                                                    : product.delivered === 3
                                                    ? 'Completed'
                                                    : product.delivered === 4
                                                    ? 'Cancelled'
                                                    : 'Pending'}
                                            </span>
                                        </h5>
                                    ) : null} */}
                                        </div>
                                        {/* <div className="listLotInfo d-flex justify-content-start align-items-center">
                                    <h5>
                                        <span>
                                            {'Vat'} ({product.tax_percent}%):
                                        </span>
                                        <span>{currencyFormat(product.total_tax)}</span>
                                    </h5>
                                </div> */}
                                        {/* <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{'Total'}:</span>
                                                <span>${formatNumber(product.total_amt)}</span>
                                            </h5>
                                        </div> */}
                                    </>
                                ) : (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{'Lot ID'}:</span>
                                                <span>{lotLPNConverter(product.auctionid)}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{'Title'}:</span>
                                                <span>{product.title}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{'Amount'}:</span>
                                                <span>{currencyFormat(product.amount)}</span>
                                            </h5>
                                            <h5>
                                                <span>{'Quantity'}:</span>{' '}
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{'Buyer Premium'}:</span>
                                                <span>
                                                    {currencyFormat(product.buypremium_amt)}
                                                </span>
                                            </h5>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
