import React, { useState, useContext, useEffect } from 'react'
import { Button, Divider } from '@material-ui/core'
import './Footer.css'
// import AutopltContext from '@/product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO, NEWLOGO, SITE_NAME } from '@/utils'
import { Link } from 'react-router-dom'
import AuthContext from '@/custom/context/auth/authContext'

const Footer = () => {
    const { isAuthenticated } = useContext(AuthContext)
    const [isVisible, setIsVisible] = useState(false)
    // const autopltContext = useContext(AutopltContext)

    // const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: 'Subscribe to our mailing list',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    // useEffect(() => {
    //     if (responseStatusAutoPlt) {
    //         if (responseStatusAutoPlt.from === 'addCustomer') {
    //             if (responseStatusAutoPlt.status === 'success') {
    //                 formik.values.email = ''
    //             }
    //         }
    //     }
    // }, [responseStatusAutoPlt])

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = document.documentElement.scrollTop
            setIsVisible(scrolled > 100)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const scrollButtonStyle = {
        position: 'fixed',
        bottom: '28%',
        right: '5%',
        padding: '10px',
        backgroundColor: 'transparent',
        border: '1px solid #A5AFBC',
        color: '#000',
        borderRadius: '100px',
        cursor: 'pointer',
    }

    return (
        <>
            <footer className="noPrint">
                <div className="customContainer pt-5">
                    <div className="footCnt">
                        <div className="logodivi">
                            <a href="/" className="footLogo">
                                <img src={NEWLOGO} alt="footLogo" />
                            </a>

                            <a
                                href={'https://www.instagram.com/dealbadger/'}
                                target="_blank"
                                rel="noreferrer"
                                className="Footinstagram mb-4"
                            >
                                Follow us on Instagram!{' '}
                                <span className="material-icons ml-2">favorite</span>
                            </a>
                        </div>
                        <div className="footLinks">
                            <ul>
                                <li>About</li>
                                <li>
                                    <Link id="footer_expect" to="/expect" onClick={scrollToTop}>
                                        What to expect
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_about" to="/about" onClick={scrollToTop}>
                                        Background
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_locations" to="/about" onClick={scrollToTop}>
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        id="footer_locations"
                                        to="/locationHours"
                                        onClick={scrollToTop}
                                    >
                                        Locations
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        id="footer_open_jobs"
                                        to="/open_jobs"
                                        onClick={scrollToTop}
                                    >
                                        Open jobs
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>Help</li>
                                <li>
                                    <Link id="footer_faq" to="/faq" onClick={scrollToTop}>
                                        FAQs
                                    </Link>
                                </li>
                                {!isAuthenticated ? (
                                    <li>
                                        <Link
                                            id="footer_expect"
                                            to="/registration"
                                            onClick={scrollToTop}
                                        >
                                            Become a customer
                                        </Link>
                                    </li>
                                ) : null}

                                <li>
                                    <Link
                                        id="footer_guidelines"
                                        to="/refer_a_friend"
                                        onClick={scrollToTop}
                                    >
                                        Refer a friend
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        id="footer_contact"
                                        to="/auction_details"
                                        onClick={scrollToTop}
                                    >
                                        Auction details
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_privacy" to="/pickup" onClick={scrollToTop}>
                                        Need help picking up?
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>Policy</li>
                                <li>
                                    <Link id="footer_terms" to="/terms" onClick={scrollToTop}>
                                        Terms & conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_privacy" to="/privacy" onClick={scrollToTop}>
                                        Privacy policy
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>Stay connected</li>
                                <li>
                                    <div className="socialContact">
                                        <a
                                            href="https://www.facebook.com/DealBadgerAuctions"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src="/assets/images/fb.png" alt="Facebook logo" />
                                        </a>
                                        <a
                                            href="https://twitter.com/DealbadgerA"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src="/assets/images/tw.png" alt="X logo" />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/dealbadger/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src="/assets/images/ig.png" alt="Instagram logo" />
                                        </a>
                                        <a
                                            href="https://www.reddit.com/r/DealBadger/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src="/assets/images/rd.png" alt="Reddit logo" />
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <Link id="footer_home" to="/contact" onClick={scrollToTop}>
                                        Contact us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="upArrow">
                            {isVisible && (
                                <span
                                    className="material-icons"
                                    onClick={scrollToTop}
                                    style={scrollButtonStyle}
                                >
                                    expand_less
                                </span>
                            )}
                        </div>
                        {/* <div className="customContainer d-flex justify-content-center align-items-center flex-wrap h-100">
                        <div className="footLinks pt-4">
                            <ul>
                                <li>
                                    <Link id="footer_home" to="/">
                                        {'Home'}
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_about" to="/about">
                                        {'About'}
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_guidelines" to="/guidelines">
                                        {'Guidelines'}
                                    </Link>
                                </li>
                                <li>
                                    <Link id="footer_contact" to="/contact">
                                        {'Contact'}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                        {/* <p className="footCopyright text-center px-4" dir="ltr">
                        © {SITE_NAME} {new Date().getFullYear()}.{'All rights reserved'}
                    </p> */}
                    </div>
                </div>
                {/* <Divider /> */}
                <p className="footCopyright">
                    Copyright © {new Date().getFullYear()} {SITE_NAME}.com{' '}
                    <div className="w-100"></div>
                    All rights reserved.
                </p>
                <div className="businessHrs">
                    <b className="tit">Customer Support Hours of Operation</b> :
                    <p className="mb-0">Tuesday - Friday, 10:00am - 6:00pm</p>
                    <p className="mb-0">Saturday, 9:00am - 12:00pm (Noon)</p>
                </div>
            </footer>
        </>
    )
}

export default Footer
