import { Button, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './ProductCard.css'
import FavouriteCheckbox from '@/custom/components/atoms/FavoriteCheckbox'
import PrimaryButton from '@/custom/components/atoms/PrimaryButton'
import SecondaryButton from '@/custom/components/atoms/SecondaryButton'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import { dateFormatFront } from '@/custom/common/components'
import { handleRedirectInternal, currencyFormat, lotLPNConverter } from '@/custom/common/components'
import CustomCommonContext from '@/custom/context/common/commonContext'
import AuthContext from '@/custom/context/auth/authContext'
// import Timer from '@/product/common/timer'
import Timer from '@/custom/common/timer'
import { FALLBACK_IMAGE } from '@/utils'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CustomDialog from '../../organisms/CustomDialog'
import moment from 'moment-timezone'

const Grid = (props) => {
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { user, isAuthenticated } = authContext
    const [discount, setDiscount] = useState('')
    const {
        configVariables,
        allLocations,
        alldata_all_dropdown,
        setBidHistoryValue,
    } = customCommonContext

    const [homeGrid, setHomeGrid] = useState()
    const [condition, setCondition] = useState(false)
    const [ctgDialog, setCtgDialog] = useState(false)
    const [product, setProduct] = useState()
    // const featureAuction = props.featureAuction
    // const homeGridCard = props.homeGridCard
    const history = useHistory()
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const handleConditionDialog = (data) => {
        setCondition(data)
        setCtgDialog(true)
    }

    const handleCloseDialog = () => {
        setCtgDialog(false)
    }
    useEffect(() => {
        // console.log(props.data, 'propsdata')

        if (product && product.msrp && product.msrp > 0) {
            let sku_count = product?.sku_count ? product?.sku_count : 1
            let msrp_org = parseFloat(product?.msrp * sku_count).toFixed(2)
            let discount =
                (parseFloat(msrp_org) - parseFloat(product.wprice).toFixed(2)) /
                parseFloat(msrp_org)
            discount = parseInt(discount * 100)
            console.log(discount, 'disccc')
            if (discount > 0) {
                setDiscount(`${discount} %`)
            } else {
                setDiscount('')
            }
        } else {
            setDiscount('')
        }
    }, [product])

    function formatDate(isoString) {
        const date = new Date(isoString)
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const dayName = days[date.getUTCDay()]
        const month = String(date.getUTCMonth() + 1).padStart(2, '0')
        const day = String(date.getUTCDate()).padStart(2, '0')
        const year = String(date.getUTCFullYear()).slice(-2)

        return `${dayName}, ${month}/${day}/${year}`
    }

    const handleTerms = () => {
        let newWindow = null
        newWindow = window.open(
            `${process.env.REACT_APP_FRONTEND_URL}/terms`,
            '_blank',
            'noopener,noreferrer',
        )

        if (newWindow) newWindow.opener = null
    }

    return (
        <div className="productCardGrid">
            {product ? (
                <>
                    <div className="pcgImg">
                        {!props.pastPage ? (
                            <FavouriteCheckbox
                                watchlisted={
                                    product.watchlist_id ? product.watchlist_id : product.wlist
                                }
                                project_id={product.id}
                            />
                        ) : null}
                        <img
                            className="cursorDecoy"
                            // onClick={handleRedirectInternal}
                            onClick={props.drawerHandler}
                            src={`${product.second_image}`}
                            onError={(e) => FALLBACK_IMAGE(e)}
                            alt={product.ptitle}
                        />
                        {/* {props.searchpage ? (
                            <div className="pcQtyCnt">
                                <span>Lot ID:</span>
                                <span>1</span>{' '}
                            </div>
                        ) : (
                            ''
                        )} */}
                    </div>
                    <div
                        className={
                            product.market_status == 'sold'
                                ? 'gridDescCnt'
                                : 'gridDescCnt custGrids'
                        }
                    >
                        {/* {props.homeGridCard ? null : <small className="status">Open-box</small>} */}
                        {console.log(
                            product,
                            product.market_status,
                            product.date_closed === product.dateFclosed,
                            'tttt',
                        )}
                        <div
                            className={
                                product.conditionunique == 'Scratch & Dent – Damaged'
                                    ? 'listBidInfo infoGrid infoGridCust justify-content-between align-items-center'
                                    : product.conditionunique == 'Scratch & Dent – New Condition' ||
                                      product.conditionunique ==
                                          'Scratch & Dent – Good Condition' ||
                                      product.conditionunique == 'Scratch & Dent – Fair Condition'
                                    ? 'listBidInfo infoGrid infoGridCust justify-content-between align-items-center'
                                    : 'listBidInfo infoGrid justify-content-between align-items-center'
                            }
                        >
                            {/* <h6>Condition :</h6> */}
                            <span
                                onClick={() => handleConditionDialog(product.conditionunique)}
                                className={`baDge ${
                                    product.conditionunique.toLowerCase() == 'appears new'
                                        ? 'Nw'
                                        : product.conditionunique.toLowerCase() == 'damaged'
                                        ? 'dm'
                                        : product.conditionunique.toLowerCase() == 'opened box'
                                        ? 'oB'
                                        : product.conditionunique == 'Scratch & Dent – Damaged'
                                        ? 'sD'
                                        : product.conditionunique ==
                                              'Scratch & Dent – New Condition' ||
                                          product.conditionunique ==
                                              'Scratch & Dent – Good Condition' ||
                                          product.conditionunique ==
                                              'Scratch & Dent – Fair Condition'
                                        ? 'Nw'
                                        : 'sv'
                                } `}
                            >
                                {product.conditionunique}
                            </span>
                            {isAuthenticated && (
                                <>
                                    {product.bidtopstatus_text && product.bidtopstatus_text !== '' && (
                                        <>
                                            {/* <h6>Condition :</h6> */}
                                            <span
                                                style={{
                                                    color: product?.bidtopstatus_color,
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    textWrap: 'nowrap',
                                                    wordBreak: 'keep-all',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                {product.bidtopstatus_text.toLowerCase() ===
                                                    "you're losing" &&
                                                (props.pastPage ||
                                                    props.expiredAuction ||
                                                    props.lostAuction)
                                                    ? 'You Lost'
                                                    : product.bidtopstatus_text}
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        {product.auctionid ? (
                            <p
                                className="d-flex justify-content-start align-items-center"
                                style={{ marginBottom: '0px', gap: '6px', fontWeight: '500' }}
                            >
                                Lot ID:
                                <span style={{ fontWeight: '450' }}>
                                    {lotLPNConverter(product.auctionid)}
                                </span>
                            </p>
                        ) : (
                            ''
                        )}

                        <div style={{ height: '44px' }}>
                            <h2
                                className="gridProdTitle"
                                // onClick={props.drawerHandler}
                                onClick={props.drawerHandler}
                                id="grid_product_title"
                            >
                                {product.title}
                            </h2>
                        </div>

                        {console.log(props.searchpage, 'props123')}
                        {props.searchpage ? (
                            <div className="d-flex align-items-center justify-content-between">
                                <small className="location">
                                    <b>{product.location}</b>
                                </small>
                                <small className="sku_count">
                                    {product.sku_count && product.sku_count > 1
                                        ? 'Ct. ' + product.sku_count
                                        : null}
                                </small>
                            </div>
                        ) : null}

                        <div style={{ minHeight: 'fit-Content' }}>
                            {!props.homeGridCard && !props.searchpage ? (
                                <small className="location d-flex justify-content-between">
                                    <b>Floor Location:</b>{' '}
                                    {product.inventory_loc ? product.inventory_loc : '-'}
                                </small>
                            ) : null}
                            {!props.searchpage ? (
                                <small className="location d-flex justify-content-between">
                                    <b>Location:</b> {product.location ? product.location : '-'}
                                </small>
                            ) : null}
                        </div>
                        {props.won ? (
                            <div style={{ minHeight: '100px' }}>
                                <small className="location d-flex justify-content-between">
                                    <b>Pickup Start Date:</b>{' '}
                                    {product.pickup_start_date ? product.pickup_start_date : '-'}
                                </small>
                                <small className="location d-flex justify-content-between">
                                    <b>Pickup End Date:</b>{' '}
                                    {product.pickup_end_date ? product.pickup_end_date : '-'}
                                </small>
                                {product.paid ? (
                                    <small className="location d-flex justify-content-between">
                                        <b>Pickup Status:</b>{' '}
                                        {product.checkout_status == 0
                                            ? 'Not Picked Up'
                                            : product.checkout_status == 1
                                            ? 'Picked Up'
                                            : product.checkout_status == 2
                                            ? `Returned ${''}${
                                                  product.checkout_reason
                                                      ? product.checkout_reason
                                                      : ''
                                              }`
                                            : product.checkout_status == 3
                                            ? 'Forfeited'
                                            : null}
                                    </small>
                                ) : null}
                            </div>
                        ) : null}
                        <div
                            className="d-flex align-items-center justify-content-start"
                            style={{ gap: '10px' }}
                        >
                            {/* <small
                                className="location condition"
                                style={{
                                    backgroundColor:
                                        product.conditionunique.toLowerCase() === 'appears new'
                                            ? 'rgb(52, 141, 62) !important'
                                            : '#d74343',
                                }}
                                onClick={handleConditionDialog}
                            >

                                {product.conditionunique}
                            </small> */}
                            {props.won && product.paid ? (
                                <small
                                    className="location condition"
                                    onClick={() =>
                                        handleRedirectInternal(
                                            history,
                                            `invoice/${product.common_invoice}`,
                                        )
                                    }
                                >
                                    {/* <h6>Condition :</h6> */}

                                    {'Paid'}
                                </small>
                            ) : null}
                        </div>
                    </div>

                    {/* 
                        <div className="gridLotDetails d-flex justify-content-start align-items-center">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.desc_proc,
                                }}
                            ></p>
                        </div> */}

                    {/* <div className="gridBidInfo d-flex justify-content-between align-items-center">
                            <h6>{t('auction')} id:</h6>
                            <p>
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}

                    {product.market_status === 'open' ? (
                        <>
                            <div className="cardFootSec">
                                {props.homeGridCard ? (
                                    <>
                                        <div className="currentBid">
                                            <p className="m-0">Current Bid</p>
                                            {''}
                                            <span className="m-0">
                                                {currencyFormat(product.wprice)}
                                            </span>
                                        </div>
                                    </>
                                ) : null}
                                {props.homeGridCard ? null : (
                                    <>
                                        <div className="gridBidInfo">
                                            <p className="price d-flex justify-content-between align-items-center">
                                                <p className="m-0">
                                                    {props.data?.proxy_bid_details
                                                        ? 'Current Bid'
                                                        : 'Starting Bid'}
                                                </p>
                                                <span>{currencyFormat(product.wprice)}</span>
                                                {/* <span className="gridDivider">|</span> */}
                                                {/* <span
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.bid_count} {'Bids'}
                                            </span> */}
                                            </p>
                                            {/* <h6 className="label">{product.cbidtext}</h6> */}
                                        </div>
                                        <div className="pvRetailTimeDiv">
                                            {props.searchpage &&
                                            product?.msrp > 0 &&
                                            product?.pricestart > 0 &&
                                            product?.msrp * product?.sku_count >
                                                product?.pricestart &&
                                            discount != '' ? (
                                                <div className="pvOfferZone">
                                                    <p className="pvRetailDiv">
                                                        Retail:{' '}
                                                        <s>
                                                            $
                                                            {product?.msrp
                                                                ? parseFloat(
                                                                      product?.msrp *
                                                                          product?.sku_count,
                                                                  ).toFixed(2)
                                                                : null}
                                                        </s>
                                                    </p>
                                                    {discount.length > 1 && (
                                                        <p className="pvRetailDiv pvDicountDiv">
                                                            Save{' '}
                                                            <span className="disCnut">
                                                                {discount}
                                                            </span>{' '}
                                                            off retail price
                                                        </p>
                                                    )}
                                                </div>
                                            ) : null}
                                            {product.market_status === 'open' ? (
                                                <div className="tickot">
                                                    {product.date_closed ? (
                                                        <Timer
                                                            date_added={product.date_added}
                                                            date_closed={product.date_closed}
                                                            timerIcon={true}
                                                        ></Timer>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <div className="gridTimer d-flex justify-content-center align-items-center">
                                                    <h6 className="m-0">{'Closed on'}:</h6>
                                                    <p className="d-flex align-items-center">
                                                        {dateFormatFront(product.date_closed)}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        {/* <div> */}
                                        {/* <p className="retailPrice">
                                                    Retail:<s>$597.00</s>
                                                </p> */}
                                        {/* <div className="tickot">
                                                {product.date_closed ? (
                                                    <Timer
                                                        date_added={product.date_added}
                                                        date_closed={product.date_closed}
                                                        timerIcon={true}
                                                    ></Timer>
                                                ) : null}
                                            </div> */}
                                        {/* </div> */}
                                    </>
                                )}
                            </div>

                            {/* <div className="cardFootSec">
                                <div className="gridBidInfo">
                                    {product.buynow && product.wprice < product.bprice ? (
                                        <p className="price d-flex justify-content-between align-items-center">
                                            {currencyFormat(product.bprice * product.qty)}
                                        </p>
                                    ) : (
                                        <p className="notAvailable">{'--'}</p>
                                    )}
                                    <h6 className="label">{'Buy Now Price'}</h6>
                                </div>
                            </div> */}
                        </>
                    ) : (
                        <>
                            {!props.pastPage && (
                                <span
                                    className="cursorDecoy"
                                    onClick={() => setBidHistoryValue(product.id)}
                                    id="grid_bidscount"
                                >
                                    {product.market_status === 'open' ? product.bids : product.bids}{' '}
                                    {'Bids'}
                                </span>
                            )}

                            <div className="d-flex justify-content-between align-items-center ">
                                {props.pastPage ? (
                                    <>
                                        <div className="gridBidInfo d-flex justify-content-between align-items-start flex-column">
                                            {product.latestbid == 0 ? null : <h6>Sold Price: </h6>}
                                            <p className="d-flex justify-content-between align-items-center">
                                                {product.latestbid == 0 ? (
                                                    <span>No Bids</span>
                                                ) : (
                                                    <span>{currencyFormat(product.wprice)}</span>
                                                )}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="gridBidInfo d-flex justify-content-between align-items-start flex-column">
                                        {product.market_status === 'open' ? (
                                            <h6>{product.starttext}: </h6>
                                        ) : (
                                            <h6>Sold Price: </h6>
                                        )}
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>{currencyFormat(product.wprice)}</span>
                                            {/* <span className="gridDivider">|</span> */}
                                        </p>
                                    </div>
                                )}
                                {props.searchpage &&
                                product?.msrp > 0 &&
                                product?.pricestart > 0 &&
                                product?.msrp * product?.sku_count > product?.pricestart &&
                                discount != '' ? (
                                    <div className="pvOfferZone">
                                        <p className="pvRetailDiv">
                                            Retail:{' '}
                                            <s>
                                                $
                                                {product?.msrp
                                                    ? parseFloat(
                                                          product?.msrp * product?.sku_count,
                                                      ).toFixed(2)
                                                    : null}
                                            </s>
                                        </p>
                                        {discount.length > 1 && (
                                            <p className="pvRetailDiv pvDicountDiv">
                                                Save <span className="disCnut">{discount}</span> off
                                                retail price
                                            </p>
                                        )}
                                    </div>
                                ) : null}
                                {/* <div className="gridBidInfo d-flex justify-content-between align-items-center"></div> */}
                            </div>
                            <h6 className="w-100 d-flex justify-content-center align-items-center mb-0">
                                Closed:{' '}
                                {product.date_closed
                                    ? moment(product.date_closed)
                                          .tz('America/New_York')
                                          .format('dddd, MM/DD/YYYY')
                                    : '-'}
                            </h6>
                        </>
                    )}
                    {props.homeGridCard ? null : (
                        <>
                            {props.from === 'dashboard' &&
                            (props.action === 'won' || props.action === 'buynow') ? (
                                <div className="actionAfterWin mt-0">
                                    {parseInt(product.paid, 10) === 1 ? (
                                        <>
                                            <SecondaryButton
                                                id="invoice_viewed"
                                                label={'View Receipt'}
                                                btnSize="small"
                                                className="mt-3"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `invoice/${product.common_invoice}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {/* <PrimaryButton
                                                id="make_pay_grid"
                                                label={'Make payment'}
                                                btnSize="small"
                                                className="mt-3"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `checkout/invoice/${product.common_invoice}`,
                                                    )
                                                }
                                            /> */}
                                        </>
                                    )}
                                </div>
                            ) : product.market_status === 'open' ? (
                                <>
                                    {/* <div
                                        className={`gvBidding ${props.quickBid ? 'quickBid' : ''}`}
                                    >
                                        <Bidding
                                            data={product}
                                            type={props.hard ? 'hard' : 'proxy'}
                                            quickBid={props.quickBid || false}
                                        />
                                    </div> */}
                                    {/* <div className="gridBidBox">
                                        <Bidding data={product} type={'proxy'} />
                                    </div> */}
                                    {/* <div className="gridBidBox">
                                {product.auction ? (
                                    <>
                                        {product.auctiontype == 'reverse' ? (
                                            <ReverseBidding
                                                data={product}
                                                type={
                                                    parseInt(
                                                        configVariables?.proxy_bidding_settings,
                                                    ) === 0
                                                        ? 'hard'
                                                        : 'proxy'
                                                }
                                            />
                                        ) : product.custom_auctionlot_bid_type ? (
                                            <Bidding
                                                data={product}
                                                type={`${product.custom_auctionlot_bid_type}`}
                                            />
                                        ) : (
                                            <Bidding data={product} type={'hard'} />
                                        )}
                                    </>
                                ) : (
                                    <Buynow
                                        data={product}
                                        editQuantity={true}
                                        isOnlyBuynow={product.auction === 0 ? true : false}
                                    />
                                )}
                            </div> */}
                                </>
                            ) : product.buynowed && product.buynowed === user?.id ? (
                                <div className="actionAfterWin">
                                    {parseInt(product.buynowpaid, 10) === 1 ? (
                                        <>
                                            <SecondaryButton
                                                id="invoice_viewed_grid"
                                                label={'View Receipt'}
                                                btnSize="small"
                                                className="mt-3"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `invoice/${product.common_invoice}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <PrimaryButton
                                                id="make_pay_grid"
                                                label={'Make payment'}
                                                btnSize="small"
                                                className="mt-3"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `checkout/invoice/${product.common_invoice}`,
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            ) : null}
                        </>
                    )}
                    {console.log(product, 'product1474')}

                    {props.searchpage && (
                        <Button
                            className={`${
                                props.pastPage ? 'pastInfoGridBtn ' : ''
                            } moreInfoGridBtn`}
                            onClick={() => {
                                // if (props.closeFunc) {
                                //     props.closeFunc(product?.id)
                                // } else {
                                handleRedirectInternal(history, `productView/${product?.id}`)
                                // }
                            }}
                        >
                            {props.pastPage ? (
                                <>
                                    <span>View more info</span>
                                    <span className="material-icons">arrow_right_alt</span>
                                </>
                            ) : (
                                'Bid Now'
                            )}
                        </Button>
                    )}
                    {/* <div className="moreInfo text-center mt-2">
                                <Button id="view_more_info" onClick={props.drawerHandler}>
                                    View more info
                                    <span
                                        className={`material-icons ${
                                            document.body.dir === 'rtl' && 'rtl'
                                        }`}
                                    >
                                        arrow_right_alt
                                    </span>
                                </Button>
                            </div> */}
                </>
            ) : null}
            <CustomDialog
                function={handleCloseDialog}
                className="conditionModal"
                title={
                    <div className="d-flex align-items-center justify-content-between">
                        Conditions
                    </div>
                }
                open={ctgDialog}
            >
                {console.log(condition, 'product.conditionunique')}
                {condition ? (
                    condition == 'Scratch & Dent – New Condition' ||
                    condition == 'Scratch & Dent – Fair Condition' ||
                    condition == 'Scratch & Dent – Good Condition' ||
                    condition == 'Scratch & Dent – Damaged' ? (
                        <div className="mb-4">
                            <p>
                                Scratch & Dent marked appliances have a 14-day Money Back Guarantee.
                                No Questions asked! All Scratch & Dent Appliances have been tested
                                and inspected by our Appliance team and are guaranteed to be in the
                                condition described. If you are not satisfied for any reason, you
                                may return the item for a full refund up to 14 days after pickup. If
                                you are not able to pick up your Scratch & Dent item within the
                                4-day pickup window, you will receive a Full Refund. The Full
                                Refund, including Hammer Price, Buyers Premium and sales tax, will
                                be issued to the credit card used at purchase. This guarantee is
                                only for appliances marked Scatch & Dent. The standard terms and
                                conditions apply to all other items. See complete terms and
                                conditions{' '}
                                <a className="cursorPointer" onClick={() => handleTerms()}>
                                    here.
                                </a>{' '}
                            </p>
                        </div>
                    ) : condition == 'APPAREL' || condition == 'Apparel' ? (
                        <>
                            <div className="mb-4">
                                <p style={{ fontSize: '16px', marginBlock: '1em' }}>
                                    <span
                                        style={{
                                            background: '#06402b',
                                            color: 'rgb(255, 255, 255)',
                                            padding: '3px',
                                        }}
                                    >
                                        APPAREL
                                    </span>{' '}
                                    items are guaranteed to be in Good to New Condition. Upon
                                    inspection, if Buyer is not completely satisfied, Buyer may
                                    request a Full refund. All refunds must be requested before
                                    removing the won item from the PUC. There are no refunds
                                    available after a won item has been removed from the PUC.
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className="mb-4">
                            <p>
                                All auction items listed on DealBadger.com are sold as{' '}
                                <u style={{ fontWeight: 'bold' }}>is-where is with all faults</u>.
                            </p>
                            <p>
                                Buyer understands DB strives to represent product condition
                                accurately, but accepts that some items will have damage, defects,
                                missing parts/pieces, or other faults that are not identified by DB
                                before posting in an auction. DB will include stock photos when
                                available, Buyer accepts that DB taken photos and stock photos may
                                be inaccurate. It is the responsibility of the Buyer to inspect all
                                items before removing the item from the PUC.
                            </p>
                            <p>
                                After a Buyer inspects their won item at the PUC, if the Buyer is
                                not fully satisfied with the condition of the item,{' '}
                                <u>BUYER MAY RETURN ANY ITEM FOR A 100% REFUND</u>. All refunds must
                                be requested before Buyer removes won items from the PUC.
                            </p>
                            <p>
                                Forfeit items, (uninspected returns) are reprocessed and charged a
                                17% restocking fee at that time.
                            </p>

                            <p>
                                Item Condition Grades are <span className="oB">OPENED BOX </span>,
                                <span className="dm">DAMAGED</span>, and{' '}
                                <span className="Nw">APPEARS NEW</span>. (See complete{' '}
                                <a
                                    onClick={() => handleRedirectInternal(history, `terms`)}
                                    style={{ cursor: 'pointer', color: 'blue' }}
                                >
                                    Terms and Conditions
                                </a>
                                ).
                            </p>
                            <ul>
                                <li>
                                    <span className="oB">OPENED BOX</span> items were sold by a
                                    retailer, and potentially used before being returned by the
                                    customer. Opened Box items <u>may be damaged</u> or have an
                                    opened box with missing pieces, missing cables, missing
                                    batteries etc.
                                </li>
                                <li>
                                    <span className="dm">DAMAGED</span> items have visible damage or
                                    could be incomplete, missing parts and have not been tested for
                                    functionality.
                                </li>
                                <li>
                                    <span className="Nw">APPEARS NEW</span> items appear to be in a
                                    new condition or have not been used previously. DB may offer a
                                    refund for all Appears New items with discovered defects.
                                </li>
                                <li>
                                    <u>
                                        Buyer MUST request to return won items to DB prior to
                                        leaving the PUC with the item
                                    </u>
                                    .
                                </li>
                            </ul>
                            <p>
                                DB will include stock photos when available, Buyer accepts that DB
                                taken photos and stock photos may be inaccurate. It is the
                                responsibility of the Buyer to inspect all items before removing a
                                won item from the PUC.
                            </p>
                            <p>
                                Buyers MUST inspect won items ONLY at the Inspection Table. All
                                Buyers are requested to inspect their items at the Inspection Table
                                prior to leaving the PUC. There are no refunds for items once they
                                have been removed from the PUC.
                            </p>
                            {/* <p>
                                Buyers may inspect
                                <span className="oB">OPENED BOX</span>,
                                <span className="dm">DAMAGED</span>
                                ,items only at the
                                <b>Inspection Table</b>.
                                <u>
                                    Buyers are not permitted to open or inspect items on the
                                    warehouse floor
                                </u>
                                .
                            </p> */}
                            {/* <p>
                                <span className="Nw">APPEARS NEW</span> items are not available to
                                be inspected before the auction closes. Winners of
                                <span className="Nw">APPEARS NEW</span> items are asked to inspect
                                their items condition before they leave the Pickup Center.
                            </p> */}
                        </div>
                    )
                ) : null}
            </CustomDialog>
        </div>
    )
}

export default Grid
