export const messageHandler = (data, product, user, setAlert, setViewProduct, type) => {
    console.log(user, 'usrtrtrt')
    let user_id = user?.user_id
    console.log(product, data, user, 'socket product')
    if (!product || product.length == 0) {
        return false
    }
    const index = product.findIndex((s) => s.id === parseInt(data.id, 10))
    let productToChange = product[index]
    console.log(index, 'what is index')
    if (index !== -1) {
        if (type === 'realclosedupdates') {
            if (data.winner !== '') {
                if (user_id === parseInt(data.winner)) {
                    console.log(data, 'dta daat')
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'Congratulations you have won this item',
                        bidtopstatus_color: 'green',
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    console.log(product, 'product after this')
                    setViewProduct([...product])
                } else if (parseInt(productToChange.cbidnot) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'Unfortunately you have lost this item',
                        buynowamount: data.buynowamount,
                        bidtopstatus_color: 'red',
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                }
            } else {
                if (parseInt(productToChange.cbidnot) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus_color: 'red',
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        bidtopstatus: 'Unfortunately you have lost this item',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'bidAddtime') {
            console.log('bpopup')
            console.log(product, 'product after this')
            let date_closed = product[index].date_closed
            if (data.bpop_belowFive !== '') {
                date_closed = data.belowFiveIncrement
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert('Bid placed successfully', 'success')
                    product[index] = {
                        ...productToChange,
                        next_bid: parseFloat(data.bpop_cuser_nextbid).toFixed(2),
                        wprice: parseFloat(data.bpop_wprice).toFixed(2),
                        bidtopstatus: data.bpop_bidstatus,
                        bidtopstatus_color: data.bpop_bidstatus_color,
                        bid_count: data.bpop_bidcount,
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        cbidnot: 1,
                        date_closed: date_closed,
                        cbidtext: 'Current Bid',
                    }
                    console.log(product, 'product after this')
                    setViewProduct([...product])
                } else {
                    console.log('coming inside 3333')
                    if (parseInt(productToChange.cbidnot) > 0) {
                        console.log('coming inside 55555', user_id, productToChange, data)
                        if (user_id === parseInt(data.bpop_higher)) {
                            console.log('123123213', parseInt(productToChange.next_bid))
                            if (parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)) {
                                console.log('faasdfsadf')
                                product[index] = {
                                    ...productToChange,
                                    next_bid: parseFloat(data.bpop_nextbid_org).toFixed(2),
                                    wprice: parseFloat(data.bpop_wprice).toFixed(2),
                                    bidtopstatus:
                                        "You're winning this item at $" +
                                        parseFloat(data.bpop_wprice).toFixed(2) +
                                        '. Your Max Bid of $' +
                                        parseFloat(data.bpop_wprice_morehigh).toFixed(2) +
                                        ' has been placed successfully.',
                                    bidtopstatus_color: 'green',
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus_text: "You're Winning",
                                    date_closed: date_closed,
                                }
                                setViewProduct([...product])
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    wprice: parseFloat(data.bpop_wprice).toFixed(2),
                                    next_bid: parseFloat(data.bpop_nextbid_org).toFixed(2),
                                    bidtopstatus:
                                        "You're winning this item at $" +
                                        parseFloat(data.bpop_wprice).toFixed(2) +
                                        '. Your Max Bid of $' +
                                        parseFloat(data.bpop_wprice_morehigh).toFixed(2) +
                                        ' has been placed successfully.',
                                    bidtopstatus_color: 'green',
                                    bidtopstatus_text: "You're Winning",
                                    bid_count: data.bpop_bidcount,
                                    date_closed: date_closed,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            console.log(
                                'coming inside outbid 55555',
                                user_id,
                                productToChange,
                                data,
                            )
                            product[index] = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: parseFloat(data.bpop_wprice).toFixed(2),
                                bid_count: data.bpop_bidcount,
                                bidtopstatus:
                                    "You're losing this item, user " +
                                    data.bpop_biddersname.charAt(0) +
                                    '****' +
                                    data.bpop_biddersname.charAt(data.bpop_biddersname.length - 1) +
                                    ' has placed a higher Max Bid.',
                                bidtopstatus_text: "You're Losing",
                                bidtopstatus_color: 'red',
                                cbidnot: 1,
                                date_closed: date_closed,
                            }
                            setViewProduct([...product])
                        }
                    } else {
                        console.log('coming inside no bids 6777777', data)
                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_nextbid_org,
                            wprice: parseFloat(data.bpop_wprice).toFixed(2),
                            bid_count: data.bpop_bidcount,
                            cbidtext: 'Current Bid',
                            date_closed: date_closed,
                        }
                        setViewProduct([...product])
                    }
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'watchlistAdded') {
            product[index] = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct([...product])
        }
    }
}

export const messageHandlerSingle = (data, product, user, setAlert, setViewProduct, type) => {
    let user_id = user.user_id
    console.log(product, data, user, 'socket product')
    const index = product.prodet.id
    // const index = product.findIndex((s) => s.id === parseInt(data.id, 10))
    let productToChange = product

    if (index == data.id) {
        if (type === 'realclosedupdates') {
            if (data.winner !== '') {
                if (user_id === parseInt(data.winner)) {
                    product = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'Congratulations you have won this item',
                        bidtopstatus_color: 'green',
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    product.prodet.market_status = 'sold'
                    setViewProduct({ ...product })
                } else if (parseInt(productToChange.prodet.cbidnot) > 0) {
                    product = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'Unfortunately you have lost this item',
                        buynowamount: data.buynowamount,
                        bidtopstatus_color: 'red',
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        cbidtext: 'Sold Price',
                    }
                    product.prodet.market_status = 'sold'
                    setViewProduct({ ...product })
                } else {
                    product = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    product.prodet.market_status = 'sold'
                    setViewProduct({ ...product })
                }
            } else {
                if (parseInt(productToChange.cbidnot) > 0) {
                    product = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'Unfortunately you have lost this item',
                        bidtopstatus_color: 'red',
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                    }
                    product.prodet.market_status = 'closed'
                    setViewProduct({ ...product })
                } else {
                    product = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    product.prodet.market_status = 'closed'
                    setViewProduct({ ...product })
                }
            }
        } else if (type === 'bidAddtime') {
            console.log('bpopup', data)
            let date_closed = product.prodet.date_closed
            if (data.bpop_belowFive !== '') {
                date_closed = data.belowFiveIncrement
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert('Bid placed successfully', 'success')
                    product = {
                        ...productToChange,
                        next_bid: parseFloat(data.bpop_cuser_nextbid).toFixed(2),
                        wprice: parseFloat(data.bpop_wprice).toFixed(2),
                        current_bid: parseFloat(data.bpop_wprice).toFixed(2),
                        bidtopstatus: data.bpop_bidstatus,
                        bidtopstatus_color: data.bpop_bidstatus_color,
                        bidtopstatus_text: data.bpop_bidtopstatus_text,
                        bid_count: data.bpop_bidcount,
                        date_closed,
                        cbidtext: 'Current Bid',
                    }
                    product.prodet.cbidnot = 1
                    product.prodet.date_closed = date_closed
                    setViewProduct({ ...product })
                } else {
                    console.log('coming inside 3333', productToChange)
                    if (parseInt(productToChange.prodet.cbidnot) > 0) {
                        console.log('coming inside 55555', user_id, productToChange, data)
                        if (user_id === parseInt(data.bpop_higher)) {
                            console.log('123123213', parseInt(productToChange.next_bid))
                            if (parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)) {
                                console.log('faasdfsadf')
                                product = {
                                    ...productToChange,
                                    next_bid: parseFloat(data.bpop_nextbid_org).toFixed(2),
                                    wprice: parseFloat(data.bpop_wprice).toFixed(2),
                                    current_bid: parseFloat(data.bpop_wprice).toFixed(2),
                                    bidtopstatus:
                                        "You're winning this item at $" +
                                        parseFloat(data.bpop_wprice).toFixed(2) +
                                        '. Your Max Bid of $' +
                                        parseFloat(data.bpop_wprice_morehigh).toFixed(2) +
                                        ' has been placed successfully.',
                                    bidtopstatus_text: data.bpop_bidtopstatus_text,
                                    bidtopstatus_color: 'green',
                                    bid_count: data.bpop_bidcount,
                                    date_closed,
                                }
                                product.prodet.date_closed = date_closed
                                setViewProduct({ ...product })
                            } else {
                                product = {
                                    ...productToChange,
                                    wprice: parseFloat(data.bpop_wprice).toFixed(2),
                                    current_bid: parseFloat(data.bpop_wprice).toFixed(2),
                                    bidtopstatus:
                                        "You're winning this item at $" +
                                        parseFloat(data.bpop_wprice).toFixed(2) +
                                        '. Your Max Bid of $' +
                                        parseFloat(data.bpop_wprice_morehigh).toFixed(2) +
                                        ' has been placed successfully.',
                                    bidtopstatus_color: 'green',
                                    bidtopstatus_text: data.bpop_bidtopstatus_text,
                                    bid_count: data.bpop_bidcount,
                                    date_closed,
                                }
                                product.prodet.date_closed = date_closed
                                setViewProduct({ ...product })
                            }
                        } else {
                            product = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: parseFloat(data.bpop_wprice).toFixed(2),
                                current_bid: parseFloat(data.bpop_wprice).toFixed(2),
                                bid_count: data.bpop_bidcount,
                                bidtopstatus:
                                    "You're losing this item, user " +
                                    data.bpop_biddersname.charAt(0) +
                                    '****' +
                                    data.bpop_biddersname.charAt(data.bpop_biddersname.length - 1) +
                                    ' has placed a higher Max Bid.',
                                bidtopstatus_color: 'red',
                                bidtopstatus_text: data.bpop_bidtopstatus_text,
                                cbidnot: 1,
                                date_closed,
                            }
                            product.prodet.date_closed = date_closed
                            product.prodet.cbidnot = 1
                            setViewProduct({ ...product })
                            console.log('coming inside cbidnot', product)
                        }
                    } else {
                        console.log('coming inside 6777777', data)
                        product = {
                            ...productToChange,
                            next_bid: data.bpop_nextbid_org,
                            current_bid: parseFloat(data.bpop_wprice).toFixed(2),
                            wprice: parseFloat(data.bpop_wprice).toFixed(2),
                            bid_count: data.bpop_bidcount,
                            cbidtext: 'Current Bid',
                            date_closed,
                        }
                        product.prodet.date_closed = date_closed
                        setViewProduct({ ...product })
                    }
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'watchlistAdded') {
            product[index] = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct([...product])
        }
    }
}
